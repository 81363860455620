import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { StaticImage } from 'gatsby-plugin-image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`About Me`}</h1>
    <p>{`User Experience and User Interaction (UX/UI) has always been my passion even before earning a Computer Science degree specializing in Information Architecture (IA) and Human-Computer Interaction (HCI) from Virginia Tech. Working with B2C, B2B, and B2B2C scenarios; I've designed for many different intentions from a consumer facing end product all to way to the development platform for project teams developing and designing their own solution.`}</p>
    <p>{`The best part about my unique position is that I get to see my designs through as a software developer. The technology industry likes to brag about the "full stack engineer", I'd like to think of myself as a "full service engineer". I can be client facing to facilitate the optimal UX design, architect a scalable and reliable solution, and implement an efficient and effective code base. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      